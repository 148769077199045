<template>
  <v-card width="100%">
    <v-card-title v-text="label" />
    <v-card-text>
      <span v-show="!isEditing" :class="{ 'text--disabled': !outputValue, 'font-italic': !outputValue }" v-text="outputValue ? outputValue : '(kosong)'" />
      <v-text-field v-show="isEditing" single-line :label="label" v-model="outputValue" />
    </v-card-text>
    <v-card-actions v-if="!isEditing" class="d-flex justify-end">
      <v-btn @click="isEditing = true" color="primary" class="ml-2">Ubah</v-btn>
    </v-card-actions>
    <v-card-actions v-else class="d-flex justify-end">
      <v-btn @click="cancelEdit" :disabled="disabled || loading" text>Batal</v-btn>
      <v-btn @click="changeValue" :disabled="disabled" color="primary" :loading="loading" dark>Simpan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ChangeableText',

  props: {
    value: String,
    label: String,
    loading: Boolean,
    disabled: Boolean,
    handler: Function,
    hint: {
      type: String,
      default: () => 'Input teks yang dikehendaki, kemudian tekan tanda centang. Tekan panah balik untuk membatalkan.'
    }
  },

  data () {
    return ({
      isEditing: false,
      outputValue: this.value,
      isLoading: this.loading,
      isDisabled: this.disabled
    })
  },

  methods: {
    async changeValue () {
      this.$data.isDisabled = true
      try {
        if (typeof this.handler === 'function') await this.handler(this.$data.outputValue)
      } catch (e) {
        /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
        console.error(e)
      }
      this.$emit('input', this.outputValue)
      this.$data.isDisabled = false
      this.$data.isEditing = false
    },

    cancelEdit () {
      this.$data.outputValue = this.value
      this.$data.isEditing = false
    }
  }
}
</script>
