<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="ma-0 pa-0" justify="center" align="center">
      <v-col xs="12" sm="12" :md="previewSrc ? 9 : 12" cols="previewSrc ? 9 : 12"><v-file-input
        v-model="internalValue"
        @change="valueChange"
        :label="label"
        :disabled="disabled"
        :loading="loading"
        :readonly="readonly"
      /></v-col>
      <v-expand-x-transition>
        <v-col v-if="previewSrc" xs="12" sm="12" md="3" cols="3" color="gray" height="275px">
          <v-img height="100%" width="100%" contain :src="previewSrc"/>
        </v-col>
      </v-expand-x-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImageFilePicker',

  props: {
    value: {
      type: File,
      required: false
    },

    label: {
      type: String,
      default: () => 'Picture'
    },

    disabled: Boolean,
    loading: Boolean,
    readonly: Boolean
  },

  data () {
    return ({
      internalValue: this.value ? this.value : null,
      previewSrc: null
    })
  },
  methods: {
    generatePreview (file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onload = () => { resolve(reader.result) }
        reader.onerror = e => { reject(e) }

        reader.readAsDataURL(file)
      })
    },

    valueChange () {
      this.generatePreview(this.$data.internalValue)
        .then(dataUrlString => { this.$data.previewSrc = dataUrlString })
        .catch(() => { this.$data.previewSrc = null })
      this.$emit('input', this.$data.internalValue)
    }
  }
}
</script>
