<template>
  <v-card>
    <v-card-title v-if="label" v-text="label" />
    <v-card-text>
      <div v-text="displayedValue" />
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn color="primary" @click="isEditing = true">Ubah</v-btn>
    </v-card-actions>
    <v-dialog width="100%" :fullscreen="!isOnPC" persistent v-model="isEditing">
      <v-card>
        <v-date-picker
          width="100%"
          color="primary"
          reactive
          v-model="datepickerValue"
          :disabled="isDisabled"
          :readonly="isReadOnly"
        />
        <v-card-actions class="d-flex justify-end">
          <v-btn text :disabled="isProcessing" @click="reset" color="primary">Batal</v-btn>
          <v-btn :disabled="noSubmit || isProcessing" :loading="isProcessing" @click="changeValue" color="primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from '@/plugins/moment'

export default {
  name: 'CardBasedDatePicker',

  props: {
    value: {
      type: [String, Date],
      validator (v) {
        if (v instanceof Date) return true
        if (moment(v).toDate() instanceof Date) return true
        return false
      }
    },
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    handler: Function
  },

  data () {
    return ({
      isEditing: false,
      isDisabled: false,
      isReadOnly: false,
      isProcessing: false,
      outputValue: moment(this.value).toDate(),
      datepickerValue: moment(this.value).format('YYYY-MM-DD')
    })
  },

  watch: {
    datepickerValue (n) {
      this.$data.outputValue = moment(n).toDate()
    }
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    noSubmit () {
      return (!this.$data.outputValue)
    },

    displayedValue () {
      return this.value ? moment(this.$data.outputValue).format('DD-MM-YYYY') : null
    }
  },

  methods: {
    reset () {
      this.$data.outputValue = moment(this.value).toDate()
      this.$data.isEditing = false
    },
    async changeValue () {
      this.$data.loading = true
      try {
        if (typeof this.handler === 'function') await this.handler(this.$data.outputValue)
      } catch (e) {
        /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
        console.error(e)
      }
      this.$emit('input', this.$data.outputValue)
      this.$data.isLoading = false
      this.$data.isEditing = false
    }
  }
}
</script>
