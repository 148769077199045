<template>
  <div>
    <v-container v-if="!loading">
      <v-row justify="center">
        <avatar-changer class="pa-4" :src="profile.photoURL" @input="changeAvatar">
          <template v-slot:activator="{ on }">
            <v-btn small v-on="on" :loading="changingAvatar" color="primary">Ganti Foto</v-btn>
          </template>
        </avatar-changer>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="6"><changeable-text label="Nama Lengkap" v-model="profile.fullName" :handler="submitToFirestore('fullName')" /></v-col>
        <v-col cols="12" xs="12" sm="6"><changeable-text label="Alamat Rumah" v-model="profile.homeAddress" :handler="submitToFirestore('homeAddress')" /></v-col>
        <v-col cols="12" xs="12" sm="6"><changeable-text label="Tempat Lahir" v-model="profile.placeOfBirth" :handler="submitToFirestore('placeOfBirth')" /></v-col>
        <!-- <v-col cols="12" xs="12" sm="6"><h5 class="text-caption font-weight-bold">Tanggal Lahir</h5></v-col> -->
        <v-col cols="12" xs="12" sm="6"><date-picker label="Tanggal Lahir" v-model="profile.dateOfBirth" :handler="submitToFirestore('dateOfBirth')" /></v-col>
        <v-col cols="12" xs="12" sm="6"><changeable-text label="Nomor Telepon" v-model="profile.phoneNumber" :handler="submitToFirestore('phoneNumber')" /></v-col>
        <v-col cols="12" xs="12" sm="6"><changeable-text label="E-mail" v-model="profile.email" :handler="submitToFirestore('email')" /></v-col>
      </v-row>
    </v-container>
    <v-container v-else class="fill-height pa-0 ma-0" fluid>
      <v-row justify="center" align="stretch">
        <v-progress-circular color="primary" indeterminate />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'
import ChangeableText from '@/components/ChangeableTextCard.vue'
import CardBasedDatePicker from '@/components/CardBasedDatePicker.vue'
import ChangeableAvatar from '@/components/ChangeableAvatar'

export default {
  name: 'AccountView',

  components: {
    'changeable-text': ChangeableText,
    'date-picker': CardBasedDatePicker,
    'avatar-changer': ChangeableAvatar
  },

  data () {
    return ({
      loading: false,
      changingAvatar: false,

      profile: {}
    })
  },

  watch: {
    profile (n) {
      if (n.dateOfBirth && !(n.dateOfBirth instanceof Date)) this.$data.profile.dateOfBirth = n.dateOfBirth.toDate()
    }
  },

  computed: {
    isOnPC () { return this.$vuetify.breakpoint.mdAndUp },
    user () { return this.$store.getters['auth/user'] },
    userClaims () { return this.$store.getters['auth/userToken'] ? this.$store.getters['auth/userToken'].claims : null }
  },

  methods: {
    getUserFromFirestore () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`profiles/${this.user.uid}`).get()
          .then(docRef => { resolve(docRef.data()) })
          .catch(e => { reject(e) })
      })
    },
    submitToFirestore (key) {
      var uid = this.user.uid
      return function (value) {
        return new Promise((resolve, reject) => {
          var o = {}; o[key] = value
          firebase.firestore().doc(`profiles/${uid}`).set(o, { merge: true })
            .then(r => { resolve(r) })
            .catch(e => { reject(e) })
        })
      }
    },

    uploadAvatar (file) {
      if (!(file instanceof File)) throw new Error('Expected argument to be File.')

      return new Promise((resolve, reject) => {
        firebase.storage().ref().child(`uploads/${this.user.uid}/avatars/${(new Date()).getTime()}-${file.name}`).put(file)
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => { resolve(url) })
          .catch(e => { reject(e) })
      })
    },

    async changeAvatar (file) {
      if (!(file instanceof File)) throw new Error('Expected argument to be File.')

      var dataFromAuth = {
        fullName: this.user.displayName,
        email: this.user.email,
        photoURL: this.user.photoURL,
        phoneNumber: this.user.phoneNumber
      }

      this.$data.changingAvatar = true
      try {
        var url = await this.uploadAvatar(file)
        await this.submitToFirestore('photoURL')(url)
        this.$data.profile = { ...dataFromAuth, ...(await this.getUserFromFirestore()) }
      } catch (e) {
        this.$store.commit('toast/setError', 'Gagal mengganti foto profil.')
      }
      this.$data.changingAvatar = false
    }
  },

  async mounted () {
    this.$data.loading = true
    var dataFromAuth = {
      fullName: this.user.displayName,
      email: this.user.email,
      photoURL: this.user.photoURL,
      phoneNumber: this.user.phoneNumber
    }

    try {
      this.$data.profile = { ...dataFromAuth, ...(await this.getUserFromFirestore()) }
    } catch (e) {
      // console.error(e)
      if (e.message !== 'not-found') {
        this.$store.commit('toast/setError', 'Gagal mengambil data profil')
      }
    }
    this.$data.loading = false
  }
}
</script>
