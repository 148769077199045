<template>
  <div class="d-flex flex-column align-center justify-space-around">
    <div class="pa-3">
      <v-avatar :size="size">
        <v-img v-if="src" :src="src || blank">
          <template v-slot:placeholder>
            <v-container fluid class="ma-0 pa-0 fill-height">
              <v-row class="ma-0 pa-0 fill-height" justify="space-around" align="center">
                <v-progress-circular indeterminate />
              </v-row>
            </v-container>
          </template>
        </v-img>
      </v-avatar>
    </div>
    <div>
      <v-dialog v-model="dialogOpen">
        <v-card width="100%">
          <v-card-title v-text="'Ganti foto'" />
          <v-card-text>
            <file-picker v-model="file" />
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn @click="dialogOpen = false" text color="primary">Batal</v-btn>
            <v-btn @click="emitNewSrc" color="primary">OK</v-btn>
          </v-card-actions>
        </v-card>

        <template v-slot:activator="slotProps">
          <slot name="activator" v-bind="slotProps">
            <v-btn v-on="slotProps.on">Change Picture</v-btn>
          </slot>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ImageFilePicker from '@/components/ImageFilePicker/ImageFilePicker'

export default {
  name: 'ChangeableAvatar',

  props: {
    size: {
      type: Number,
      default: () => 128
    },

    src: String
  },

  components: {
    'file-picker': ImageFilePicker
  },

  data () {
    return ({
      dialogOpen: false,
      file: null,
      blank: require('@/assets/blank-avatar.png')
    })
  },

  methods: {
    emitNewSrc () {
      this.$emit('input', this.$data.file)
      this.$data.dialogOpen = false
    }
  }
}
</script>
